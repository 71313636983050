import React, { useEffect } from "react";
import { redirectTo } from "src/helpers/route";
const Redirect = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      redirectTo("/links");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return <p>Redirecionando...</p>;
};
export default Redirect;

/*
import React from "react";
import Subscribe from "src/components/CTA/Subscribe";
import SEO from "src/components/SEO";
import Social from "src/components/Social";
import YoutubeVideo from "src/components/YoutubeVideo";

import {
  NAME,
  BASE_URL,
  CONVERTKIT_FORM_ID_SEP_2021,
} from "src/helpers/config";
import "./maratona.scss";

const MaratonaJS = () => {
  const seoProps = {
    title: `Maratona JS 3 - Participe - ${NAME}`,
    description: `Maratona JS 3 - Participe desse evento 100% online e 100% gratuito que acontecerá nos dias 13 a 17 de Setembro!`,
    canonical: `${BASE_URL}/maratona-js/`,
    image: `${BASE_URL}/meta/og-image-maratona-js-3.jpeg`,
  };

  const videoId = "SqtbBFYLCuI";
  return (
    <div className="links  maratona-js-3">
      <SEO {...seoProps} />
      <div className="inner-container">
        <div className="logo">
          <img
            loading="lazy"
            src="https://emersonbroga.com/e/assets/maratona-js-3-logo-white.png"
            alt="Emerson Broga Logo"
          />
        </div>
        <div className="js">
          <p style={{ textAlign: "center" }}>
            Venha participar gratiutamente da&nbsp;
            <strong style={{ color: "white" }}>Maratona JS 3</strong>, um evento
            100% online e 100% gratuito que acontecerá nos dias&nbsp;
            <strong style={{ color: "white" }}>13 à 17 de Setembro</strong>
          </p>
          <YoutubeVideo id={videoId} />
          <p style={{ textAlign: "center" }}>Se inscreva abaixo e participe</p>
          <Subscribe
            title="Quero participar gratuitamente"
            secondaryTitle="Quero participar gratuitamente"
            formId={CONVERTKIT_FORM_ID_SEP_2021}
            isModal={false}
          />
        </div>
        <span>Veja minhas redes sociais</span>
        <Social type="light" />
      </div>
    </div>
  );
};
export default MaratonaJS;
*/
